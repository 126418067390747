<template>
  <div>
    <!-- 公共头部 -->
    <Header title=""></Header>

    <div class="section1">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="cate-title">{{ item.subject_title }}</div>
        <div class="box">
          <div class="pic">
            <img class="icon" :src="item.img" />
          </div>
          <div class="content">
            <div class="title">培训校区：{{ item.school_title }}</div>
            <div class="desc">考试时间：{{ item.start }}-{{ item.end }}</div>
            <div class="button-group">
              <div class="btn exam_num">剩余{{ item.exam_num }}次</div>
              <div class="btn btn1" @click="openMask(index)">开始考试</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tip">请正确选择您的考试项目。</div>

    <div class="mask" v-if="showMask"></div>
    <div class="alert-box" :class="[showMask ? 'active' : '']">
      <h2 class="cate-title">考试确认</h2>
      <div class="desc" v-html="msg"></div>
      <div class="button_group">
        <div class="btn" @click="closeMask">取消</div>
        <div class="btn btn1" @click="examination">开始考试</div>
      </div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      list: [],
      showMask: true,
      msg: "",
      student_id: "",
      id: "",
    };
  },
  activated() {
    this.getList();
  },
  deactivated() {
    this.list = [];
    this.showMask = false;
    this.msg = "";
    this.student_id = "";
    this.id = "";
  },
  methods: {
    async getList() {
      var cardid = this.$store.state.cardid;
      var data = {
        cardid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.post("/exam/examList", data);
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "正式考试 项目列表：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        var list = res.data;
        for (var i = 0; i < list.length; i++) {
          list[i]["start"] = this.utils.js_date_time(list[i]["start"], 3);
          list[i]["end"] = this.utils.js_date_time(list[i]["end"], 3);
          if (list[i]["img"] && list[i]["img"].substring(0, 4) != "http") {
            list[i]["img"] = this.$store.state.http + list[i]["img"];
          }
        }
        this.list = list;
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
    openMask(index) {
      this.msg = this.list[index].explain;
      this.student_id = this.list[index].student_id;
      this.id = this.list[index].id;
      this.showMask = true;
    },
    closeMask() {
      this.showMask = false;
    },
    examination() {
      var student_id = this.student_id;
      var id = this.id;
      this.$router.push({
        path: "/Examination/index",
        query: { student_id, id },
      });
    },
  },
};
</script>

<style scoped>
.section1 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
.section1 .item {
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
  margin-top: 0.4rem;
}
.section1 .item:first-child {
  margin-top: 0;
}
.section1 .item .cate-title {
  height: 0.72rem;
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  color: #333333;
  padding: 0 0.3rem;
  border-bottom: 0.02rem solid #e0e0e0;
}
.section1 .item .box {
  padding: 0.2rem 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section1 .item .box .pic {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
}
.section1 .item .box .pic .icon {
  max-height: 100%;
}
.section1 .item .box .content {
  width: 5rem;
  /* height: 1.6rem; */
}
.section1 .item .box .content .title {
  font-size: 0.26rem;
  color: #333333;
  margin-bottom: 0.18rem;
}
.section1 .item .box .content .desc {
  font-size: 0.26rem;
  color: #333333;
}
.section1 .item .box .content .button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.3rem;
}
.section1 .item .box .content .button-group .btn {
  width: 1.4rem;
  height: 0.44rem;
  font-size: 0.24rem;
  color: #333333;
  border: 0.02rem solid #333333;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.3rem;
}
.section1 .item .box .content .button-group .btn:last-child {
  margin-right: 0;
}
.section1 .item .box .content .button-group .btn1 {
  border: 0.02rem solid #ff6000;
  background-color: #ff6000;
  color: #fff;
  /* color: #ff6000; */
}
.section1 .item .box .content .button-group .exam_num {
  background-color: #08852d;
  color: #fff;
  border: 0.02rem solid #08852d;
}
.tip {
  margin: 0 0.24rem;
  padding-left: 0.5rem;
  font-size: 0.28rem;
  color: #ff6000;
  background: url(../../assets/images/icon81.png) no-repeat left center;
  background-size: 0.34rem 0.34rem;
}

.alert-box {
  position: fixed;
  width: 6rem;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.14rem;
  padding: 0.4rem 0.6rem;
  transition: all 0.3s;
}
.alert-box.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box .cate-title {
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  margin-bottom: 0.3rem;
}
.alert-box .desc {
  font-size: 0.26rem;
  color: #000000;
  line-height: 0.42rem;
}
.alert-box .button_group {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alert-box .button_group .btn {
  width: 2rem;
  height: 0.7rem;
  border-radius: 0.5rem;
  font-size: 0.32rem;
  color: #ffffff;
  background: #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box .button_group .btn1 {
  background: #121e5f;
}
</style>